/** @jsx jsx */
import { jsx } from "theme-ui"
import { ReactNode } from "react"

interface SocialMediaWrapperProps {
  children: ReactNode
  url: string
}
const SocialMediaWrapper = ({ children, url }: SocialMediaWrapperProps) => {
  return (
    <a href={url} rel="noopener noreferrer nofollow" target="_blank">
      {children}
    </a>
  )
}

export default SocialMediaWrapper

/** @jsx jsx */
import { jsx, Card, Flex, Box, Heading } from "theme-ui";
import { theme } from "gatsby-plugin-theme-ui/index";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

interface MoveYourBrandCardProps {
  className?: string;
}
const MoveYourBrandCard = ({ className }: MoveYourBrandCardProps) => {
  return (
    <Card variant="moveYourBrand" className={className}>
      <Box className="columnLeft">
        <Heading
          as="h3"
          variant="styles.h3"
          className="heading"
          sx={{
            maxWidth: "450px",
            color: "white",
          }}
        >
          Mōve your brand forward now.
        </Heading>
        <a
          href="https://motus.eightfold.cloud/uploads/Motus_Sales_Packet_7e1e410920.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
          sx={{
            ...theme?.links?.arrow,
            color: "white",
          }}
        >
          Download our free Mōve Forward PDF
        </a>
      </Box>
      <Box className="columnRight">
        <StaticImage
          src="../../images/move-brand-stack.png"
          alt="Image Card Stack"
          width={404}
          height={235}
          sx={{
            mt: [4, 0],
          }}
        />
      </Box>
    </Card>
  );
};

export default MoveYourBrandCard;

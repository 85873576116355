import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

export interface ISEO {
  title: string;
  description: string;
  shareImage: any;
}
const SEO = ({ title, description, shareImage }: ISEO) => {
  const {
    strapiGlobal: { site_name, DefaultSEO },
  } = useStaticQuery(seoQuery);

  const mergedSeo = { title, description, shareImage, DefaultSEO };
  // console.log(mergedSeo)

  // Title
  const mergedTitle = mergedSeo.title
    ? `${mergedSeo.title} | ${site_name}`
    : `${mergedSeo.DefaultSEO.meta_title} | ${site_name}`;

  // Description
  const mergedDescription = mergedSeo.description
    ? mergedSeo.description
    : mergedSeo.DefaultSEO.meta_description;

  // URL of the share iamge
  const shareImageUrl = mergedSeo.shareImage
    ? (process.env.GATSBY_ROOT_URL || 'http://localhost:8000') +
      mergedSeo.shareImage.localFile.publicURL
    : (process.env.GATSBY_ROOT_URL || 'http://localhost:8000') +
      DefaultSEO.share_image.localFile.publicURL;

  return (
    <Helmet title={mergedTitle}>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta name="description" content={mergedDescription} />
      <meta name="image" content={shareImageUrl} />
    </Helmet>
  );
};

export default SEO;

export const seoQuery = graphql`
  query SEO {
    strapiGlobal {
      site_name
      favicon {
        localFile {
          publicURL
        }
      }
      DefaultSEO {
        meta_title
        meta_description
        share_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

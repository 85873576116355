/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const FooterLogo = () => {
  return (
    <Box
      sx={{
        mt: [4, 0]
      }}
    >
      <Link to="/">
        <StaticImage
          src="../../images/logo/logo-blue.svg"
          alt="Motus logo"
          layout="fixed"
          width={183}
          height={55}
        />
      </Link>
    </Box>
  )
}

export default FooterLogo

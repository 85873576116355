/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { theme } from 'gatsby-plugin-theme-ui/index';
import { Link } from 'gatsby';

import HeaderLogo from 'components/Header/HeaderLogo';

import { HeaderTheme } from 'helpers/myTypes';
import { navLinks } from 'helpers/navLinks';

interface DesktopNavProps {
  headerTheme: HeaderTheme;
}
const DesktopNav = ({ headerTheme }: DesktopNavProps) => {
  const getFirstHalfOfNavLinks = () => {
    return navLinks.slice(0, navLinks.length / 2);
  };

  const getSecondHalfOfNavLinks = () => {
    return navLinks.slice(navLinks.length / 2, navLinks.length);
  };

  return (
    <nav
      sx={{
        a: {
          color:
            headerTheme === 'WHITE' || headerTheme === 'HOME'
              ? 'white'
              : 'themeBlack',
        },
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
        }}
      >
        {/* Left side of the centered logo */}
        <ul
          sx={{
            p: 0,
            display: 'flex',
            justifyContent: 'flex-end',
            flex: '0 1 50%',
            listStyle: 'none',
            'li:not(:first-of-type)': {
              ml: 4,
            },
          }}
        >
          {getFirstHalfOfNavLinks().map(({ text, url }, index) => (
            <li key={`desktop-nav:${index}`}>
              <Link to={url} sx={theme?.links?.nav}>
                {text}
              </Link>
            </li>
          ))}
        </ul>

        {/* Centered logo */}
        <HeaderLogo
          headerTheme={headerTheme}
          sx={{
            mx: 5,
            flex: '1 0 285px',
          }}
        />

        {/* Right side of the centered logo */}
        <ul
          sx={{
            p: 0,
            flex: '0 1 50%',
            display: 'flex',
            listStyle: 'none',
            'li:not(:first-of-type)': {
              ml: 4,
            },
          }}
        >
          {getSecondHalfOfNavLinks().map(({ text, url }, index) => (
            <li key={`DesktopNav:${index}`}>
              <Link to={url} sx={theme?.links?.nav}>
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </Flex>
    </nav>
  );
};

export default DesktopNav;

/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading } from "theme-ui";
import { theme } from "gatsby-plugin-theme-ui/index";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import MoveYourBrandCard from "components/Footer/MoveYourBrandCard";
import FooterLogo from "components/Footer/FooterLogo";
import SocialMediaWrapper from "components/Common/SocialMediaWrapper";

import { IMyLink } from "helpers/myTypes";

const links: IMyLink[] = [
  {
    text: "Services",
    url: "/services",
  },
  {
    text: "Contact",
    url: "/contact",
  },
  {
    text: "Blog",
    url: "/blog",
  },
  {
    text: "About",
    url: "/about",
  },
];

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      strapiSocialMediaLinks {
        linked_in
        instagram
        youtube
        facebook
      }
    }
  `);

  return (
    <footer
      sx={{
        pb: 5,
        pt: 6,
        position: "relative",
        zIndex: "front",
        borderTopWidth: "11px",
        borderTopStyle: "solid",
        borderImage: "linear-gradient(270deg, #1A8FAB 0.03%, #66D6C0 100%) 1",
        bg: "white",
      }}
    >
      {/* 
        Main content
        X padding is done here because Container already has padding applied to it, and we need to control the footer's Y padding from outside of the component
      */}
      <Container
        variant="container800"
        sx={{
          px: [5, 3],
        }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Heading as="h4" variant="styles.h4">
              Motus Agency
            </Heading>
            <ul
              sx={{
                p: 0,
                mt: 2,
                listStyle: "none",
                li: {
                  mb: 1,
                },
              }}
            >
              {links.map(({ text, url }, index) => (
                <li key={`FooterNavLink:${index}`}>
                  <Link to={url} sx={theme?.links?.footer}>
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          </Box>

          <Box>
            <Heading as="h4" variant="styles.h4">
              Contact
            </Heading>
            <ul
              sx={{
                p: 0,
                mt: 2,
                listStyle: "none",
                li: {
                  mb: 1,
                },
              }}
            >
              <li>
                <a
                  href="mailto:info@motus.agency"
                  target="_blank"
                  sx={theme?.links?.footer}
                >
                  info@motus.agency
                </a>
              </li>
            </ul>
          </Box>

          <Flex
            sx={{
              flexDirection: ["column-reverse", "column"],
              alignSelf: "flex-start",
            }}
          >
            <FooterLogo />
            <ul
              sx={{
                p: 0,
                mt: 5,
                display: "flex",
                justifyContent: "space-between",
                listStyle: "none",
              }}
            >
              <li>
                <SocialMediaWrapper url={data.strapiSocialMediaLinks.linked_in}>
                  <StaticImage
                    src="../../images/icons/linkedin.svg"
                    alt="Click here to check out our LinkedIn"
                  />
                </SocialMediaWrapper>
              </li>
              <li>
                <SocialMediaWrapper url={data.strapiSocialMediaLinks.instagram}>
                  <StaticImage
                    src="../../images/icons/instagram.svg"
                    alt="Click here to check out our Instagram"
                  />
                </SocialMediaWrapper>
              </li>
              <li>
                <SocialMediaWrapper url={data.strapiSocialMediaLinks.youtube}>
                  <StaticImage
                    src="../../images/icons/youtube.svg"
                    alt="Click here to check out our YouTube channel"
                  />
                </SocialMediaWrapper>
              </li>
              <li>
                <SocialMediaWrapper url={data.strapiSocialMediaLinks.facebook}>
                  <StaticImage
                    src="../../images/icons/facebook.svg"
                    alt="Click here to check out our Facebook"
                  />
                </SocialMediaWrapper>
              </li>
            </ul>
          </Flex>
        </Flex>
      </Container>
    </footer>
  );
};

export default Footer;

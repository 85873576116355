/** @jsx jsx */
import { jsx } from 'theme-ui';

import MobileHeader from 'components/Header/MobileHeader';
import DesktopHeader from 'components/Header/DesktopHeader';

import { HeaderTheme } from 'helpers/myTypes';

export interface HeaderProps {
  className?: string;
  theme: HeaderTheme;
}
const Header = ({ theme, className }: HeaderProps) => {
  return (
    <header className={className} sx={{ position: 'relative' }}>
      <MobileHeader theme={theme} />
      <DesktopHeader theme={theme} />
    </header>
  );
};

export default Header;

/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

import DesktopNav from 'components/Header/DesktopNav';

import { HeaderProps } from 'components/Header/Header';

const DesktopHeader = ({ theme }: HeaderProps) => {
  return (
    <Box
      sx={{
        py: [4, 5],
        display: ['none', 'block'],
        position: 'relative',
        zIndex: 999,
        background:
          theme === 'HOME'
            ? 'linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0))'
            : 'none',
      }}
    >
      <DesktopNav headerTheme={theme} />
    </Box>
  );
};

export default DesktopHeader;

/** @jsx jsx */
import { jsx, Box, Container, Flex } from "theme-ui"
import { useState } from "react"

import HeaderLogo from "components/Header/HeaderLogo"
import NavToggle from "components/Header/NavToggle"
import MobileNav from "./MobileNav"

import { HeaderProps } from "components/Header/Header"
import { useDisableBodyScroll } from "hooks/useDisableBodyScroll"

const MobileHeader = ({ theme }: HeaderProps) => {
  const [navIsExpanded, setNavIsExpanded] = useState(false)
  const toggleNavIsExpanded = () => {
    setNavIsExpanded(!navIsExpanded)
  }
  useDisableBodyScroll(navIsExpanded)

  return (
    <Box
      sx={{
        height: "66px",
        display: ["block", "none"]
      }}
    >
      {/* "Header" portion */}
      <Container
        variant="mobileHeader"
        sx={{
          height: "100%",
          position: "relative",
          zIndex: 998
        }}
      >
        <Flex
          sx={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <HeaderLogo headerTheme={theme} />
          <NavToggle
            headerTheme={theme}
            navIsExpanded={navIsExpanded}
            toggleNav={toggleNavIsExpanded}
          />
        </Flex>
      </Container>

      <MobileNav isExpanded={navIsExpanded} toggleNav={toggleNavIsExpanded} />
    </Box>
  )
}

export default MobileHeader

import { useEffect } from "react"

export const useDisableBodyScroll = (bindState: boolean) => {
  useEffect(() => {
    document.body.style.overflow = bindState ? "hidden" : "visible"

    return () => {
      document.body.style.overflow = "visbile"
    }
  }, [bindState])
}
/** @jsx jsx */
import { jsx, Button } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';

import { HeaderTheme } from 'helpers/myTypes';

interface NavToggleProps {
  headerTheme: HeaderTheme;
  navIsExpanded: boolean;
  toggleNav: () => void;
}
const NavToggle = ({
  headerTheme,
  navIsExpanded,
  toggleNav,
}: NavToggleProps) => {
  return (
    <Button
      onClick={toggleNav}
      variant="icon"
      sx={{
        cursor: 'pointer',
      }}
    >
      {navIsExpanded ? (
        <StaticImage
          src="../../images/icons/white-x-icon.svg"
          alt="Click to close the navigation"
        />
      ) : headerTheme === 'WHITE' || headerTheme === 'HOME' ? (
        <StaticImage
          src="../../images/icons/hamburger-icon-white.svg"
          alt="Click to open the navigation"
        />
      ) : (
        <StaticImage
          src="../../images/icons/hamburger-icon-black.svg"
          alt="Click to open the navigation"
        />
      )}
    </Button>
  );
};

export default NavToggle;

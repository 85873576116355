/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { ReactNode } from 'react';

import SEO from 'components/Common/SEO';
import Footer from 'components/Footer/Footer';

import { ISEO } from 'components/Common/SEO';
import Header from 'components/Header/Header';
interface LayoutProps {
  children: ReactNode | ReactNode[];
  seo: ISEO;
  headerColor?: 'WHITE' | 'BLUE' | 'HOME';
  className?: string;
}
const Layout = ({
  children,
  seo,
  headerColor = 'WHITE',
  className,
}: LayoutProps) => {
  return (
    <>
      <SEO {...seo} />
      <div
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <Header theme={headerColor} />

        <main
          className={className}
          sx={{
            mt: '-215px',
            flex: '1 100%',
          }}
        >
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

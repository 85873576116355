/** @jsx jsx */
import { jsx, Box, Flex, Button, Container } from "theme-ui"
import { theme } from "gatsby-plugin-theme-ui"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import HeaderLogo from "components/Header/HeaderLogo"

import { navLinks } from "helpers/navLinks"

interface MobileNavProps {
  isExpanded: boolean
  toggleNav: () => void
}
const MobileNav = ({ isExpanded, toggleNav }: MobileNavProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        position: "absolute",
        zIndex: 999,
        left: isExpanded ? 0 : "-100vw",
        transition: "left 0.2s ease-out",
        top: 0,
        display: "flex",
        flexDirection: "column",
        bg: "themeBlue"
      }}
    >
      <Container
        variant="mobileHeader"
        sx={{
          height: "66px"
        }}
      >
        <Flex
          sx={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <HeaderLogo headerTheme="WHITE" />
          {/* Close button */}
          <Button onClick={toggleNav} variant="icon">
            <StaticImage
              src="../../images/icons/white-x-icon.svg"
              alt="Click to close the navigation"
            />
          </Button>
        </Flex>
      </Container>

      <nav
        sx={{
          a: {
            color: "white"
          }
        }}
      >
        <ul
          sx={{
            pl: 5,
            mt: 3,
            listStyle: "none",
            li: {
              my: 4
            }
          }}
        >
          {navLinks.map(({ text, url }, index) => (
            <li key={`mobile-nav-links:${index}`}>
              <Link to={url} sx={theme?.links?.mobileNav}>
                {text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </Box>
  )
}

export default MobileNav

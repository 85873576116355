import { IMyLink } from "helpers/myTypes"

export const navLinks: IMyLink[] = [
  {
    url: "/services",
    text: "Services"
  },
  {
    url: "/case-studies",
    text: "Case Studies"
  },
  {
    url: "/about",
    text: "About"
  },
  {
    url: "/contact",
    text: "Contact"
  }
]

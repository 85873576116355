/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeaderTheme } from 'helpers/myTypes';

import BlueLogo from 'images/logo/logo-blue.svg';
import WhiteLogo from 'images/logo/logo-white.svg';

interface HeaderLogoProps {
  headerTheme: HeaderTheme;
  className?: string;
}
const HeaderLogo = ({ className, headerTheme }: HeaderLogoProps) => {
  return (
    <Link
      to="/"
      className={className}
      sx={{
        width: ['103px', '286px'],
        height: ['31px', '86px'],
        display: 'inline-block',
      }}
    >
      {headerTheme === 'WHITE' || headerTheme === 'HOME' ? (
        <img
          src={WhiteLogo}
          sx={{ width: '100%', height: 'auto' }}
          alt="White Motus logo"
          loading="eager"
        />
      ) : (
        <img
          src={BlueLogo}
          sx={{ width: '100%', height: 'auto' }}
          alt="Blue Motus logo"
          loading="eager"
        />
      )}
    </Link>
  );
};

export default HeaderLogo;
